import React, { useMemo } from 'react'
import { Flex, Skeleton, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { Pool } from 'state/types'
import BaseCell, { CellContent } from './BaseCell'

interface DepositFeeCellProps {
  pool: Pool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const DepositFeeCell: React.FC<DepositFeeCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { depositFee } = pool

  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {t('Deposit fee')}
        </Text>
        {depositFee >= 0 ? (
          <Flex height="20px" alignItems="center">
            <Balance fontSize="16px" value={depositFee / 100} decimals={0} unit="%" />
          </Flex>
        ) : (
          <Skeleton width="80px" height="16px" />
        )}
      </CellContent>
    </StyledCell>
  )
}

export default DepositFeeCell
