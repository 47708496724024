import React from 'react'
import styled from 'styled-components'
import { Box } from '@pancakeswap/uikit'
import Container from '../Layout/Container'

const Outer = styled(Box)`
  padding-top: 16px;
`

const Inner = styled(Container) <{ backgroundColor?: string; backgroundImage?: string }>`
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.gradients.bubblegum};
  background-image: ${({ backgroundImage }) => backgroundImage};
  background-repeat: no-repeat;
  background-position: left;
  background-size: 150px;
  padding-top: 40px;
  padding-bottom: 50px;
  padding-left: 180px;
  border-radius: 16px;
`

const PageHeader: React.FC<{ backgroundColor?: string; backgroundImage?: string }> = ({ backgroundColor, backgroundImage, children, ...props }) => (
  <Outer {...props}>
    <Inner backgroundColor={backgroundColor} backgroundImage={backgroundImage}>{children}</Inner>
  </Outer>
)

export default PageHeader
