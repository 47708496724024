// import React from 'react'
// import styled from 'styled-components'
// import { Spinner } from '@pancakeswap/uikit'


// const Wrapper = styled(Page)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

// const PageLoader: React.FC = () => {
//   return (
//     <Wrapper>
//       <Spinner />
//     </Wrapper>
//   )
// }

// export default PageLoader

import React from 'react'
import styled, { keyframes } from 'styled-components'
import Page from '../Layout/Page'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.colors.primary};
  }
`

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function PageLoader({ size = '64px', stroke, ...rest }: { size?: string; stroke?: string }) {
  return (
    <Wrapper>
      <StyledSVG viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
        <path
          d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </StyledSVG>
    </Wrapper>
  )
}
