import React from 'react'
import { Button } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { ConnectButton } from '@rainbow-me/rainbowkit';

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button onClick={openConnectModal} {...props}>
                    {t('Connect Wallet')}
                  </Button>
                );
              }
              return <></>
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  )
}

export default ConnectWalletButton
