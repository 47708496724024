import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'r-flex';
    src: url('fonts/r-flex-bold-webfont.woff2') format('woff2'),
        url('fonts/r-flex-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'r-flex';
    src: url('fonts/r-flex-bolditalic-webfont.woff2') format('woff2'),
        url('fonts/r-flex-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'r-flex';
    src: url('fonts/r-flex-regular-webfont.woff2') format('woff2'),
        url('fonts/r-flex-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'r-flex';
    src: url('fonts/r-flex-regularitalic-webfont.woff2') format('woff2'),
        url('fonts/r-flex-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }

  * {
    font-family: 'Kanit', sans-serif;
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};
    // background-image: url('/images/custom/swap-bg.png');
    // background-size: cover;
    // background-repeat: repeat;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
