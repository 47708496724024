import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  // {
  //   sousId: 0,
  //   stakingToken: tokens.birb,
  //   earningToken: tokens.birb,
  //   contractAddress: {
  //     97: '',
  //     56: '0x551DCE34A8aDd5FA5cCE12fA83187e4084007918',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: true,
  // },
  
  {
    sousId: 1,
    stakingToken: tokens.birb,
    earningToken: tokens.birb,
    contractAddress: {
      97: '',
      56: '0xa464164c9A828c535EB77333a44363E49bd19fb3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.136'
  },
  {
    sousId: 2,
    stakingToken: tokens.birb,
    earningToken: tokens.birb,
    contractAddress: {
      97: '',
      56: '0x9ae9B41FC80F6B9Cfbc1BA827E90D219f9883a78',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.37'
  },
  {
    sousId: 3,
    stakingToken: tokens.birb,
    earningToken: tokens.birb,
    contractAddress: {
      97: '',
      56: '0x7bebD063d8fDF1CeD4C7E098193Be74D698fB224',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.15'
  },
  {
    sousId: 4,
    stakingToken: tokens.birb,
    earningToken: tokens.birb,
    contractAddress: {
      97: '',
      56: '0x4A15cE33DCa1E70c57Ad2FAdEfe90E6AE5146541',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.1'
  },
  {
    sousId: 5,
    stakingToken: tokens.birbv2,
    earningToken: tokens.birbv2,
    contractAddress: {
      97: '',
      56: '0x6caBD804077AEBc5E594d4e5F4f26f9F82519D89',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.1'
  },
  {
    sousId: 6,
    stakingToken: tokens.birbv3,
    earningToken: tokens.birbv3,
    contractAddress: {
      97: '',
      56: '0x64bF79ca12CB373adFe366bA212095D78a012ABA',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.1'
  },
  {
    sousId: 7,
    stakingToken: tokens.birbv3,
    earningToken: tokens.birbv3,
    contractAddress: {
      97: '',
      56: '0x462B2703D990ADfd15b926667aB80D6e257b61A0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.68'
  },
  {
    sousId: 8,
    stakingToken: tokens.birbv4,
    earningToken: tokens.birbv4,
    contractAddress: {
      97: '',
      56: '0x803fcE3F38226CF1Db53C15a36df33481DdB9cf2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.4'
  },
  {
    sousId: 9,
    stakingToken: tokens.birbv4,
    earningToken: tokens.birbv4,
    contractAddress: {
      97: '',
      56: '0xE7c4dEf5DE65cd5A4CEe850CC64B8C31a6127060',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.79'
  },
  {
    sousId: 10,
    stakingToken: tokens.birbv4,
    earningToken: tokens.birbv4,
    contractAddress: {
      97: '',
      56: '0x55597c2105D798eD35b5f1397EF4E214FdC460F1',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.61'
  },

  {
    sousId: 11,
    stakingToken: tokens.birbv4,
    earningToken: tokens.birbv4,
    contractAddress: {
      97: '',
      56: '0xB9Fb43535A39bA1Dab2138Ce4108A1866c2759f7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.46'
  },

  {
    sousId: 12,
    stakingToken: tokens.birbv4,
    earningToken: tokens.birbv4,
    contractAddress: {
      97: '',
      56: '0x6d7aFFa80C551Ea53F738B19F7D93F063782464A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.4498'
  },

  {
    sousId: 13,
    stakingToken: tokens.birbv4,
    earningToken: tokens.birbv4,
    contractAddress: {
      97: '',
      56: '0x4411F12a15Ff727f8602155dB47678B889cD3C69',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.457'
  },

  {
    sousId: 14,
    stakingToken: tokens.birbv4,
    earningToken: tokens.birbv4,
    contractAddress: {
      97: '',
      56: '0x07d5eE6A47d772A9b8f16cb0a4ad7CE3247c015F',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.416'
  },

  // {
  //   sousId: 6,
  //   stakingToken: tokens.gbirb,
  //   earningToken: tokens.btcb,
  //   contractAddress: {
  //     97: '',
  //     56: '0xD3b886F65a31f4090dDbEFa5E6CfB90cf7df9A38',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '0.00000029762',
  //   isVault: true
  // },
  // {
  //   sousId: 7,
  //   stakingToken: tokens.gbirb,
  //   earningToken: tokens.doge,
  //   contractAddress: {
  //     97: '',
  //     56: '0xd9fC3deec56Be2DE253dC85D0d8008aB3e44Df59',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '0.0496',
  //   isVault: true
  // },
]

export default pools
