import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'BIRB',
    lpAddresses: {
      97: '',
      56: '0x82a479264b36104be4fdb91618a59a4fc0f50650',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },

  // apeswap lps
  {
    pid: 1,
    lpSymbol: 'BIRB-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x8F53e5940d5ADFB07e271d2812DCcdb5B6380C62',
    },
    token: tokens.birb,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BIRB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x91d394d9d491962d36E45C217D31707DF9233bBC',
    },
    token: tokens.birb,
    quoteToken: tokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 4,
    lpSymbol: 'BIRBV2-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x2121251F733ef2c1a3Ca5Eb8Afa6c5E80a109630',
    },
    token: tokens.birbv2,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'BIRBV3-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xc5F6db481E5c78d208C641d589E13C19fA329878',
    },
    token: tokens.birbv3,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 6,
    lpSymbol: 'BIRB2.0-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xB41B9AC5fd5DD8B7c221220494daC13a34aa3006',
    },
    token: tokens.birbv4,
    quoteToken: tokens.wbnb,
  }
]

export default farms
