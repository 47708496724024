import React from 'react'
import { ModalProvider, light, dark } from '@pancakeswap/uikit'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useThemeManager } from 'state/user/hooks'
import { LanguageProvider } from 'contexts/Localization'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import store from 'state'
import Web3RainbowKitProvider from 'contexts/RainbowKitContext'
import { ActiveWeb3Provider } from 'contexts/Web3Context'

const ThemeProviderWrapper = (props) => {
  const [isDark] = useThemeManager()
  return <ThemeProvider theme={isDark ? dark : light} {...props} />
}

const Providers: React.FC = ({ children }) => {
  return (
    <Web3RainbowKitProvider>
      <ActiveWeb3Provider>
        <Provider store={store}>
          <ToastsProvider>
            <HelmetProvider>
              <ThemeProviderWrapper>
                <LanguageProvider>
                  <RefreshContextProvider>
                    <ModalProvider>{children}</ModalProvider>
                  </RefreshContextProvider>
                </LanguageProvider>
              </ThemeProviderWrapper>
            </HelmetProvider>
          </ToastsProvider>
        </Provider>
      </ActiveWeb3Provider>
    </Web3RainbowKitProvider>
  )
}

export default Providers
